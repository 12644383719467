import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class TeamService extends BaseService {
    constructor() {
        super(COLLECTION.TEAMS);
    }

    async checkTeamExist(teamName, id) {
        const filters = { name: { value: teamName, matchMode: '==' } };
        if (id) {
            filters.DOCUMENT_ID = {
                value: id,
                matchMode: '!='
            };
        }
        const { size } = await this.get({ filters });
        return size;
    }
}
export default new TeamService();
