import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class DistrictService extends BaseService {
    constructor() {
        super(COLLECTION.DISTRICTS);
    }
}

export default new DistrictService();
