import { BaseService } from './';
import { COLLECTION, USER_ROLE } from '../shared/constants';
import { Status } from '../shared/enums';

class VisitLocationService extends BaseService {
    constructor() {
        super(COLLECTION.VISITLOCATIONS);
    }

    async getVisitLocationsByManager(userDetails) {
        const filter = {
            state: { matchMode: '==', value: Status.Active }
        };
        if (userDetails.role != USER_ROLE.ADMIN) {
            filter.managers = {
                matchMode: 'array-contains',
                value: {
                    name: userDetails.name,
                    id: userDetails.firebaseId,
                    email: userDetails.email
                }
            };
        }
        return await this.getAll(filter);
    }
}

export default new VisitLocationService();
