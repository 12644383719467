import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class TimesheetsService extends BaseService {
    constructor() {
        super(COLLECTION.TIMESHEET);
    }
}

export default new TimesheetsService();
