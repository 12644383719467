import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class PurposeService extends BaseService {
    constructor() {
        super(COLLECTION.PURPOSES);
    }

    async checkPurposeExist(purposeName) {
        const { size } = await this.get({ filters: { title: { value: purposeName, matchMode: '==' } } });
        return size;
    }
}

export default new PurposeService();
