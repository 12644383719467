import { BaseService } from './';
import { COLLECTION, chunkSize } from '../shared/constants';

class BuildingService extends BaseService {
    constructor() {
        super(COLLECTION.BUILDINGS);
    }

    async getBuildingByBoroughAndDistrict(boroughId, districtId) {
        return await this.getSnapshot({
            boroughId: { matchMode: '==', value: boroughId },
            districtId: { matchMode: '==', value: districtId },
            isInScope: { matchMode: '==', value: 1 }
        });
    }
    async getBuildingsById(buildings) {
        buildings = [...new Set(buildings)];
        const promises = [];
        for (let i = 0; i < buildings.length; i += chunkSize) {
            promises.push(
                this.getAll({
                    DOCUMENT_ID: { matchMode: 'in', value: buildings.slice(i, i + chunkSize) },
                    isInScope: { matchMode: '==', value: 1 }
                })
            );
        }
        const result = await Promise.all(promises);
        return result.flat();
    }
}

export default new BuildingService();
