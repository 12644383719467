import { initializeApp, getApps, getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getFirestore, serverTimestamp, Timestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import config from '../build/firebase.config';
const firebaseApp = getApps().length ? getApp() : initializeApp(config);

const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(config.recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true
});

export { firebaseApp, firestore, Timestamp, storage };
export default firebaseApp;
export function serverTimeStamp() {
    return serverTimestamp();
}
