import { BaseService } from './';
import { COLLECTION, chunkSize } from '../shared/constants';

class LocationService extends BaseService {
    constructor() {
        super(COLLECTION.LOCATIONS);
    }
    async getLocationByBuildingId(locations) {
        locations = [...new Set(locations)];
        const promises = [];
        for (let i = 0; i < locations.length; i += chunkSize) {
            promises.push(
                this.getAll({
                    DOCUMENT_ID: { matchMode: 'in', value: locations.slice(i, i + chunkSize) },
                    isInScope: { matchMode: '==', value: 1 }
                })
            );
        }
        const result = await Promise.all(promises);
        return result.flat();
    }
}
export default new LocationService();
