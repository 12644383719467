import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DocumentType, ProviderState } from '../enums/index';
import { HttpStatus } from '../enums/http-staus';
import { localStorageKeys } from '../constants';
import store from '../store';

class Utility {
    convertSecondsToMoment(timestamp) {
        return timestamp && moment.unix(timestamp);
    }
    formateDate(timestamp, format = 'M/D/YY') {
        switch (typeof timestamp) {
        case 'number':
            return moment.unix(timestamp).format(format);
        case 'object':
            return timestamp? timestamp.format(format): ' ';
        case 'string':
            return moment(timestamp).format(format);
        default:
            return null;
        }
    }
    formateTime(timestamp, format = 'HH:mm') {
        switch (typeof timestamp) {
        case 'number':
            return moment.unix(timestamp).format(format);
        case 'object':
            return timestamp? moment(timestamp).format(format): ' ';
        case 'string':
            return moment(timestamp).format(format);
        default:
            return null;
        }
    }
    addInDate(unit, unitType, date = moment()) {
        return date && date.add(unit, unitType);
    }
    formatNumberToLocaleString(value) {
        return value.toLocaleString();
    }
    convertSecondsToDateTime(timestamp) {
        return this.convertSecondsToMoment(timestamp)?.toDate();
    }
    convertDateToString() {
        return new Date().toLocaleDateString('en-GB');
    }

    getStartDayOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    getEndDayOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    getStartOfDayDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }
    getEndOfDayDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    }

    isSameOrBefore(start, end) {
        const startMoment = moment(start);
        const endMoment = moment(end);
        return startMoment.isSameOrBefore(endMoment, 'day');
    }

    isBefore(start, end) {
        const startMoment = moment(start);
        const endMoment = moment(end);
        return startMoment.isBefore(endMoment, 'day');
    }

    getDuration(startSeconds, endSeconds) {
        let day = moment.unix(startSeconds).startOf('day');
        let countDays = 0;
        const weekend = { 'Sunday': 0, 'Saturday': 6 };
        const totalDays = moment.unix(endSeconds).endOf('day').diff(moment.unix(startSeconds).startOf('day'), 'days');
        for (let countTotalDays = 0; countTotalDays <= totalDays; countTotalDays++) {
            if ( moment(day).day() != weekend.Sunday && moment(day).day() != weekend.Saturday ) {
                countDays += 1;
            }
            day = moment(day).add(1, 'day');
        }

        return countDays;
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    removeInBetweenSpaces(textString) {
        return textString
            .split(' ')
            .filter(s => s)
            .join(' ');
    }

    findLast(arr, func) {
        let k = arr?.length;
        while (k > 0) {
            if (func(arr[k - 1])) {
                return arr[k - 1];
            }
            k--;
        }
        return null;
    }

    uniqueFileName(fileName) {
        const tempFileName = fileName.split('.');
        const finalFileName = tempFileName[0].concat('_', uuidv4(), '.', tempFileName[1]);
        return finalFileName;
    }

    getFileType(fullFileType) {
        const array = fullFileType.split('/');
        if (array.includes('pdf')) {
            return this.getKeyByValue(DocumentType, DocumentType.PDF);
        } else if (array.includes('image')) {
            return this.getKeyByValue(DocumentType, DocumentType.Image);
        } else {
            return this.getKeyByValue(DocumentType, DocumentType.Other);
        }
    }

    getFileTypeValue(fileExtension) {
        const array = fileExtension.split('/');
        if (array.includes('pdf')) {
            return DocumentType.PDF;
        } else if (array.includes('image')) {
            return DocumentType.Image;
        } else {
            return DocumentType.Other;
        }
    }

    getExcludedDate(startDate, endDate) {
        const localData = [];
        const selectedDate = [];
        while (startDate <= endDate) {
            startDate = this.addInDate(1, 'd', startDate);
            const local = { name: this.formateDate(startDate, 'dddd, MM/D/YYYY'), milliseconds: startDate.startOf('day').valueOf() };
            localData.push(local);
            if (startDate.isoWeekday() > 5) {
                local.disabled = true;
                selectedDate.push(local);
            }
        }
        return {
            selectedDate,
            localData
        };
    }

    sendRequest(url, type, data) {
        return new Promise((resolve, reject) => {
            console.log('sending request');
            const xhr = new XMLHttpRequest();
            xhr.open(type, url, false);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Accept', 'application/json');

            xhr.onload = function(response) {
                if (response.currentTarget.status == HttpStatus.Ok) {
                    resolve(response.currentTarget.response);
                } else {
                    reject(response);
                }
            };
            xhr.onerror = function(error) {
                console.log('Upload data xhr error');
                reject(error);
            };
            if (type == 'POST' && data) {
                xhr.send(JSON.stringify(data));
            } else if (type == 'GET' ) {
                xhr.send();
            }
        });
    }

    getUniqueElements(data) {
        return [...new Set(data)];
    }

    getSum(condition, data) {
        let workHourSum = 0;
        let breakHourSum = 0;
        let scannedSum = 0;
        let sumGap = 0;
        let sumDuration = 0;
        let gapSum = 0;
        let durationSum = 0;
        let sumCheckin = 0;
        let sumExpected = 0;
        let avg = 0;
        let daap = 0;
        let total = 0;
        let inConflict = 0;
        let autoResolved = 0;
        let manuallyResolved = 0;
        let readyForSNOW = 0;
        let unresolved = 0;
        let deferred = 0;

        data?.forEach(x => {
            if (x.workHours) workHourSum += parseFloat(x.workHours);
            if (x.breakHours) breakHourSum += parseFloat(x.breakHours);
            if (x.scanned) scannedSum += parseInt(x.scanned);
            if (x.gap_m) sumGap += parseFloat(x.gap_m);
            if (x.duration_m) sumDuration += parseFloat(x.duration_m);
            if (x.gap_s) gapSum += x.gap_s;
            if (x.duration_s) durationSum += parseFloat(x.duration_s);
            if (x.break_m) sumCheckin+= parseFloat(x.break_m);
            if (x.expected) sumExpected += parseInt(x.expected);
            if (x.avg) avg += parseFloat(x.avg);
            if (x.daap) daap +=x.daap;
            if (x.total) total +=x.total;
            // eslint-disable-next-line max-len
            if (x.inConflict) inConflict +=x.inConflict;
            if (x.autoResolved) autoResolved += x.autoResolved;
            if (x.manuallyResolved) manuallyResolved += x.manuallyResolved;
            if (x.readyForSNOW) readyForSNOW += x.readyForSNOW;
            if (x.unesolved) unresolved += x.unresolved;
            if (x.deferred) deferred += x.deferred;
        });
        switch (condition) {
        case 'workHours': return workHourSum.toFixed(2);
        case 'breakHours': return breakHourSum.toFixed(2);
        case 'scanned': return scannedSum;
        case 'gap_m': return sumGap.toFixed(2);
        case 'duration_m': return sumDuration.toFixed(2);
        case 'gap': return gapSum;
        case 'duration': return durationSum.toFixed(2);
        case 'checkin': return sumCheckin.toFixed(2);
        case 'expected': return sumExpected;
        case 'avg': return avg.toFixed(2);
        case 'daap': return daap;
        case 'total': return total;
        case 'inConflict': return inConflict;
        case 'autoResolved': return autoResolved;
        case 'manuallyResolved': return manuallyResolved;
        case 'unresolved': return unresolved;
        case 'deferred': return deferred;
        case 'readyForSNOW': return readyForSNOW;
        }
    }

    getAverage(data) {
        const initialValue = 0;
        return data.length ? (data.reduce((a, element) => a + parseFloat(element.avg), initialValue) / data.length).toFixed(2) : 0;
    }

    getUniqueCount(data) {
        const uniqueItems = new Set(data);
        return uniqueItems.size;
    }

    preventCharactersInNumberField(event) {
        const charToPrevent = ['.', '+', 'e', 'E'];
        if (charToPrevent.includes(event.key)) {
            event.preventDefault();
        }
    }

    getProviderString(provider) {
        switch (parseInt(provider)) {
        case ProviderState.Unknown: return 'Unknown';
        case ProviderState.Central: return 'Central';
        case ProviderState.School: return 'School';
        case ProviderState.Other: return 'Other';
        default: return provider;
        }
    }

    compareData(data1, data2) {
        if (!data1 && data2) return -1;
        else if (data1 && !data2) return 1;
        else if (data1 === data2) return 0;
        else return (data1 > data2) ? 1 : (data2 > data1 ? -1 : 0);
    }
    arrayEquals(array1, array2) {
        return Array.isArray(array1) &&
            Array.isArray(array2) &&
            array1.length === array2.length &&
            array1.every((val, index) => val === array2[index]);
    }
    removeKeysFromLocalStorage() {
        const loggedInUserId = store.getters.getSessionUser.uid;
        Object.keys(localStorage).forEach(key => {
            const [keyName, userId] = key.split('-');
            if ((keyName == localStorageKeys.resolveConflictFilter || keyName == localStorageKeys.selectedVisit) &&
            userId != loggedInUserId) {
                localStorage.removeItem(`${ localStorageKeys.resolveConflictFilter }-${ userId }`);
                localStorage.removeItem(`${ localStorageKeys.selectedVisit }-${ userId }`);
            }
        });
    }
}

export default new Utility();
