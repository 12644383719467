import { documentId, where, query, Timestamp } from 'firebase/firestore';
import { filterMatchMode } from '../constants';
import { DeletedEntitiesService } from '../../apis';
import Utility from '../utility/utility';
import store from '../store';

const InEqualityFilter = ['<', '<=', '!=', 'not-in', '>', '>='];
const EqualityFilter = ['==', 'in'];
const ARRAY_CONTAINS_ANY = 'array-contains-any';
const ArrayFilter = ['in', 'not-in', ARRAY_CONTAINS_ANY];
export const InParamMaxSize = 10;

export function getWhereOperators(str) {
    switch (str) {
    case 'equals':
        return '==';
    case 'notEquals':
        return '!=';
    case 'lt':
        return '<';
    case 'gt':
        return '>';
    case 'lte':
        return '<=';
    case 'gte':
        return '>=';
    }
    return str;
}

function paginate(array, pageNumber, pageSize = InParamMaxSize) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
function firebaseKeyMap(key) {
    switch (key) {
    case 'DOCUMENT_ID':
        return documentId();
    }
    return key;
}

export function getQueryAfterFilter(filters, q, queryPageNumber = 1, executeOrQuery = false) {
    let mustOrderBy = null;
    let hasRemainingSearch = false;
    let isFilterOnAny = false;
    const shouldNotOrderByAny = [];
    if (executeOrQuery) {
        queryPageNumber = 1;
    }
    let subFilter = null;
    for (let [key, filter] of Object.entries(filters || {})) {
        if (!Array.isArray(filter)) filter = [filter];
        filter.forEach(({ value, matchMode, constraints }) => {
            subFilter = subFilter || constraints;
            if (subFilter?.length && executeOrQuery) return;
            matchMode = getWhereOperators(matchMode);
            key = firebaseKeyMap(key);
            let isAddedWhere = false;
            if (ArrayFilter.includes(matchMode)) {
                if (Array.isArray(value) && value.length) {
                    hasRemainingSearch = hasRemainingSearch || value.length > InParamMaxSize * queryPageNumber;
                    isFilterOnAny = isFilterOnAny || matchMode === ARRAY_CONTAINS_ANY;
                    isAddedWhere = true;
                    q = query(q, where(key, matchMode, paginate(value, queryPageNumber)));
                }
            } else if (JSON.stringify(matchMode) == JSON.stringify(filterMatchMode.rangeOperator)) {
                for (let i = 0; i < value?.length; i++) {
                    if (value[i]) {
                        q = query(q, where(key, matchMode[i], new Date(value[i])));
                        isAddedWhere = true;
                    }
                }
            } else if (value || value === 0) {
                q = query(q, where(key, matchMode, value));
                isAddedWhere = true;
            }
            if (isAddedWhere) {
                if (EqualityFilter.includes(matchMode)) {
                    shouldNotOrderByAny.push(key);
                } else if (JSON.stringify(matchMode) == JSON.stringify(filterMatchMode.rangeOperator)) {
                    mustOrderBy = key;
                } else if (InEqualityFilter.includes(matchMode)) {
                    mustOrderBy = key;
                }
            }
        });
        if (!hasRemainingSearch && subFilter?.length) {
            if (executeOrQuery) {
                const subQuery = getQueryAfterFilter(subFilter[0], q, queryPageNumber, false);
                q = subQuery.query;
            } else {
                hasRemainingSearch = true;
                executeOrQuery = true;
            }
        }
    }
    return { q, mustOrderBy, hasRemainingSearch, isFilterOnAny, shouldNotOrderByAny, executeOrQuery };
}

export function groupBy(xs, key) {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export const insertDeletedEntity = async (docId, entityName) => {
    const [startDate, upToDate] = [Utility.getStartOfDayDate(new Date()), Utility.getEndOfDayDate(new Date())];
    const filter = { datestamp: { value: [startDate, upToDate], matchMode: filterMatchMode.rangeOperator } };
    const todaysDeletedEntityDoc = await DeletedEntitiesService.getAll(filter);
    const deletedInfo = { id: docId, by: store.getters.getSessionUser.email };
    if (todaysDeletedEntityDoc[0]) {
        await DeletedEntitiesService.updateDeletedEntities(todaysDeletedEntityDoc[0].id, deletedInfo, entityName);
    } else {
        const data = {
            datestamp: Timestamp.now(),
            [entityName]: [deletedInfo]
        };
        await DeletedEntitiesService.create(data);
    }
};

