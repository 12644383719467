import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class SlotService extends BaseService {
    constructor() {
        super(COLLECTION.SLOTS);
    }

    async checkSlotExist(slotName) {
        const { size } = await this.get({ filters: { title: { value: slotName, matchMode: '==' } } });
        return size;
    }
}

export default new SlotService();
