import { storage } from '../../firebase';
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';

class FileStorageService {
    async uploadFile(fileName, fileData) {
        const storageRef = ref(storage, fileName);
        const result = await uploadBytes(storageRef, fileData);
        return await getDownloadURL(result.ref);
    }

    async getDownloadURL(fileName) {
        const storageRef = ref(storage, fileName);
        return await getDownloadURL(storageRef);
    }

    async deleteFile(fileName) {
        const fileRef = ref(storage, fileName);
        await deleteObject(fileRef);
    }
}
export default new FileStorageService();
