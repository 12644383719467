import { serverTimeStamp } from '../../../firebase';
import store from '../store';
import { userActivities } from '../constants';

export class UserActivity {
    static getInfo(isArchive = true) {
        return {
            info: {
                ...this.getActivity(userActivities.created),
                ...this.getActivity(userActivities.updated),
                ...this.getActivity(userActivities.archived, isArchive == true)
            }
        };
    }
    static getActivity(type, isCreateNode = true, userId) {
        return {
            [type]: isCreateNode ?{
                by: store.getters.getSessionUser?.uid || userId,
                when: serverTimeStamp()
            } : {}
        };
    }
}
