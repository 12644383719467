import { COLLECTION } from '../shared/constants';
import BaseService from './BaseService';
import FileStorageService from './FileStorageService';
import AuthService from './AuthService';
import AssetService from './AssetService';
import AssetTypeService from './AssetTypeService';
import BoroughService from './BoroughService';
import BuildingService from './BuildingService';
import DistrictService from './DistrictService';
import DocumentService from './DocumentService';
import LocationService from './LocationService';
import SlotService from './SlotService';
import TeamService from './TeamService';
import UserService from './UserService';
import VisitLocationService from './VisitLocationService';
import VisitService from './VisitService';
import TimesheetsService from './TimesheetsService';
import SettingsService from './SettingsService';
import AssetVerificationsService from './AssetVerificationsService';
import AssetMapService from './AssetMapService';
import PurposeService from './PurposeService';
import VisitAnalysisService from './VisitAnalysisService';
import VisitActivityService from './VisitActivityService';
import DeletedEntitiesService from './DeletedEntitiesService';

export {
    BaseService,
    FileStorageService,
    AssetService,
    AssetTypeService,
    AuthService,
    BoroughService,
    BuildingService,
    DistrictService,
    DocumentService,
    LocationService,
    SlotService,
    TeamService,
    TimesheetsService,
    UserService,
    VisitLocationService,
    VisitService,
    SettingsService,
    PurposeService,
    VisitAnalysisService,
    AssetVerificationsService,
    AssetMapService,
    VisitActivityService,
    DeletedEntitiesService
};

export function serviceFactory(collectionName) {
    switch (collectionName) {
    case COLLECTION.ASSETS:
        return AssetService;
    case COLLECTION.ASSETTYPES:
        return AssetTypeService;
    case COLLECTION.BOROUGHS:
        return BoroughService;
    case COLLECTION.BUILDINGS:
        return BuildingService;
    case COLLECTION.DISTRICTS:
        return DistrictService;
    case COLLECTION.DOCUMENTS:
        return DocumentService;
    case COLLECTION.LOCATIONS:
        return LocationService;
    case COLLECTION.SLOTS:
        return SlotService;
    case COLLECTION.TEAMS:
        return TeamService;
    case COLLECTION.TIMESHEET:
        return TimesheetsService;
    case COLLECTION.USERS:
        return UserService;
    case COLLECTION.VISITS:
        return VisitService;
    case COLLECTION.VISITLOCATIONS:
        return VisitLocationService;
    case COLLECTION.SETTINGS:
        return SettingsService;
    case COLLECTION.ASSETVERIFICATIONS:
        return AssetVerificationsService;
    case COLLECTION.PURPOSES:
        return PurposeService;
    case COLLECTION.DELETEDENTITIES:
        return DeletedEntitiesService;
    default:
        new BaseService(collectionName);
    }
}
