export const UserRole = {
    'Admin': 1,
    'User': 2,
    'Ops Manager': 3,
    'BackOffice Manager': 4,
    'Data Manager': 5,
    'Lead Agent': 6,
    'Agent': 7,
    'Building Manager': 8,
    'Business': 9
};
