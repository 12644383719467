import {
    caseSplitRegex,
    sortOrder
} from '../constants';

const getSplitedText = text => {
    const res = text.replace(caseSplitRegex, '$2$3$4$5 ');
    const array = ['Type Id', 'Building Id', 'Location Id'];
    if (array.includes(res)) {
        return (res.split(' ')[0]);
    }
    return res;
};

const sortObjectArray = (input, sortOn, sortDirection) => {
    sortDirection = sortDirection || sortOrder.Ascending;
    input.sort((a, b) => {
        let result = null;
        const value1 = a[sortOn];
        const value2 = b[sortOn];
        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }
        return sortDirection * result;
    });
    return input;
};

export {
    getSplitedText,
    sortObjectArray
};

