module.exports = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENTID,
  recaptchaSiteKey: process.env.VUE_APP_FIREBASE_CONFIG_RECAPTCHA_SITE_KEY
};
