import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class DocumentService extends BaseService {
    constructor() {
        super(COLLECTION.DOCUMENTS);
    }

    async checkDocumentNameExist(docName, id) {
        const filters = { name: { matchMode: '==', value: docName } };
        if (id) {
            filters.DOCUMENT_ID = {
                value: id,
                matchMode: '!='
            };
        }
        const { size } = await this.get({ filters });
        return size;
    }
}
export default new DocumentService();
