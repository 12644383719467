/* eslint-disable prefer-const */
import { BaseService } from '.';
import { COLLECTION } from '../shared/constants';
import { VisitActivityState } from '../shared/enums';

class VisitActivityService extends BaseService {
    constructor() {
        super(COLLECTION.VISIT_ACTIVITIES);
    }
    async getAssetList(visitId) {
        const response = await this.getById(visitId);
        let assetIdArray = [];
        if (response) {
            Object.values(response).forEach(x => {
                Array.isArray(x) && x.forEach(y => {
                    if (y.what == VisitActivityState.CheckIn && y.data.assets.length) {
                        y.data.assets.forEach(id=>{
                            if (id) {
                                assetIdArray.push(id);
                            }
                        });
                    }
                });
            });
        }
        return assetIdArray;
    }
}
export default new VisitActivityService();
