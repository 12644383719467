import { createRouter, createWebHistory } from 'vue-router';
import { AuthService } from '../../apis';
import { isNavigationAllowed } from '../utils';
import { NAVIGATIONCONST } from '../constants';
import store from '../store';

const DEFAULT_TITLE = 'IIQ';

const routes = [
    {
        path: '/noAccess',
        name: 'NoAccess',
        component: () => import('../../modules/noAccess/NoAccess.vue'),
        meta: {
            title: 'NoAccess'
        },
        beforeEnter: async () => {
            await AuthService.getCurrentUser();
        }
    },
    {
        path: '/noInternet',
        name: 'NoInternet',
        component: () => import('../../modules/noAccess/NoInternet.vue'),
        meta: {
            title: 'NoInternet'
        },
        beforeEnter: async () => {
            await AuthService.getCurrentUser();
        }
    },
    {
        path: '/pendingApproval',
        name: 'PendingApproval',
        component: () => import('../../modules/pendingApproval/PendingApproval.vue'),
        meta: {
            title: 'PendingApproval'
        },
        beforeEnter: async () => {
            await AuthService.getCurrentUser();
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../../modules/home/Home.vue'),
        beforeEnter: routerFilter,
        meta: {
            title: 'Dashboard'
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                redirect: '/visits',
                // component: () => import('../../modules/visits/components/all-visits/AllVisits.vue'),
                // component: () => import('../../modules/dashboard/Dashboard.vue'),
                beforeEnter: checkInternet,
                meta: {
                    title: 'Dashboard',
                    name: 'Home'
                }
            },
            {
                path: '/users',
                name: 'UsersAndAccessControl',
                component: () => import('../../modules/users/Users.vue'),
                meta: {
                    title: 'UsersAndAccessControl'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/teams',
                name: 'Teams',
                component: () => import('../../modules/teams/components/all-teams/AllTeams.vue'),
                meta: {
                    title: 'Teams'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/documents',
                name: 'Documents',
                component: () => import('../../modules/documents/components/all-documents/AllDocuments.vue'),
                meta: {
                    title: 'Documents'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/visits',
                name: 'Visits',
                component: () => import('../../modules/visits/components/all-visits/AllVisits.vue'),
                meta: {
                    title: 'Visits'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/slots',
                name: 'Slots',
                component: () => import('../../modules/slots/components/all-slots/AllSlots.vue'),
                meta: {
                    title: 'Slots'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/purposes',
                name: 'Purposes',
                component: () => import('../../modules/UnderConstruction.vue'),
                // component: () => import('../../modules/purposes/all-purposes/AllPurposes.vue'),
                meta: {
                    title: 'Purposes'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/assets',
                name: NAVIGATIONCONST.ASSETS,
                component: () => import('../../modules/assets/components/all-assets/AllAssets.vue'),
                meta: {
                    title: 'Assets'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/ourassets',
                name: NAVIGATIONCONST.OURASSETS,
                component: () => import('../../modules/assets/components/all-assets/AllAssets.vue'),
                meta: {
                    title: 'Our Assets'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/scheduleNewVisit',
                name: 'ScheduleNewVisit',
                component: () => import('../../modules/schedule/ScheduleNewVisit.vue'),
                meta: {
                    title: 'Schedule New Visit'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/allSchedules',
                name: 'AllSchedules',
                component: () => import('../../modules/UnderConstruction.vue'),
                // component: () => import('../../modules/UnderConstruction.vue'),
                meta: {
                    title: 'All Schedules'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/ourSchedules',
                name: 'OurSchedules',
                component: () => import('../../modules/UnderConstruction.vue'),
                meta: {
                    title: 'Our Schedules'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/todaysVisit',
                name: 'TodaysVisits',
                component: () => import('../../modules/visits/components/all-visits/AllVisits.vue'),
                meta: {
                    title: 'TodaysVisits'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/ourVisits',
                name: 'OurVisits',
                component: () => import('../../modules/visits/components/all-visits/AllVisits.vue'),
                meta: {
                    title: 'Our Visits'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/myVisits',
                name: 'myVisits',
                component: () => import('../../modules/visits/components/all-visits/AllVisits.vue'),
                meta: {
                    title: 'My Visits'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/activityLog',
                component: () => import('../../modules/UnderConstruction.vue'),
                meta: {
                    title: 'ActivitiesLog'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/ourActivitiesLog',
                name: 'OurActivitiesLog',
                component: () => import('../../modules/UnderConstruction.vue'),
                meta: {
                    title: 'Our Activities Log'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/timesheets',
                name: 'Timesheets',
                component: () => import('../../modules/UnderConstruction.vue'),
                // component: () => import('../../modules/timesheets/components/all-timesheets/Timesheet.vue'),
                meta: {
                    title: 'Timesheets'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/mytimesheets',
                name: 'MyTimeSheets',
                component: () => import('../../modules/UnderConstruction.vue'),
                meta: {
                    title: 'My TimeSheets'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/resolveConflict/:assetId?',
                name: NAVIGATIONCONST.RESOLVECONFLICT,
                component: () => import('../../modules/assets/components/asset-conflict'),
                meta: {
                    title: 'Resolve Conflict'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/resolveScanConflicts/:assetId?',
                name: NAVIGATIONCONST.RESOLVESCANCONFLICTS,
                component: () => import('../../modules/assets/components/all-assets/AllAssets.vue'),
                meta: {
                    title: 'Resolve Scan Conflicts'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/locations',
                name: 'Locations',
                component: () => import('../../modules/visit_location/components/all-visitLocations/AllVisitLocations.vue'),
                meta: {
                    title: 'Locations'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/resolveConflicts',
                name: NAVIGATIONCONST.RESOLVECONFLICTS,
                component: () => import('../../modules/assets/components/all-assets/AllAssets.vue'),
                meta: {
                    title: 'Resolve Conflicts'
                },
                beforeEnter: checkInternet
            },
            {
                path: '/visitAnalysis/:id',
                name: NAVIGATIONCONST.VISITANALYSIS,
                component: () => import('../../modules/reports/VisitAnalysis.vue'),
                meta: {
                    title: 'Visit Analysis'
                },
                beforeEnter: checkInternet
            }
        ]
    },
    {
        path: '/changepassword',
        name: 'ChangePassword',
        component: () => import('../../shared/authentication/ChangePassword.vue'),
        beforeEnter: routerFilter,
        meta: {
            title: 'ChangePassword'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../../shared/authentication/Login.vue'),
        beforeEnter: skipScreenRouterFilter,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: () => import('../../shared/authentication/ForgetPassword.vue'),
        beforeEnter: skipScreenRouterFilter,
        meta: {
            title: 'ForgotPassword'
        }
    },
    {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: () => import('../../shared/authentication/ResetPassword.vue'),
        beforeEnter: skipScreenRouterFilter,
        meta: {
            title: 'ResetPassword'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../../shared/authentication/Register.vue'),
        beforeEnter: skipScreenRouterFilter,
        meta: {
            title: 'Register'
        }
    },
    {
        path: '/passwordRecovery',
        name: 'PasswordRecovery',
        component: () => import('../../shared/authentication/PasswordRecovery.vue'),
        beforeEnter: skipScreenRouterFilter,
        meta: {
            title: 'PasswordRecovery'
        }
    },
    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*', component: () => import('../../shared/authentication/PageNotFound.vue'),
        meta: {
            title: 'Page Not Found'
        }
    }
];


async function routerFilter(to, from, next) {
    setTitle(to);
    if (!navigator.onLine) {
        next('/noInternet');
    } else if (await AuthService.getCurrentUser() === null) {
        next('/login');
    } else if (!isNavigationAllowed(to.meta.name || to.name)) {
        next('/noAccess');
    } else {
        next();
    }
}

async function skipScreenRouterFilter(to, from, next) {
    setTitle(to);
    if (!navigator.onLine) {
        next('/noInternet');
    } else if (await AuthService.getCurrentUser()) {
        next(store.getters.landingPage || '/noAccess');
    } else if (to.path === '/passwordRecovery' && from.path !== '/forgotpassword') {
        next('/forgotpassword');
    } else {
        next();
    }
}

async function checkInternet(to, from, next) {
    setTitle(to);
    if (!navigator.onLine) {
        next('/noInternet');
    } else {
        next();
    }
}

function setTitle(to) {
    document.title = to.meta.title || DEFAULT_TITLE;
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const routeMap = r => ({ id: r.name, displayName: r.meta.title, to: r.path });
const routeNavigation = routes.map(routeMap).concat(
    routes
        .filter(r => r.children)
        .map(r => r.children)
        .flat()
        .map(routeMap)
);
export { routeNavigation };
export default router;
