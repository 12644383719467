import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class BoroughService extends BaseService {
    constructor() {
        super(COLLECTION.BOROUGHS);
    }
}

export default new BoroughService();
