import { HttpStatus } from '../../shared/enums/http-staus';
import { AuthService } from '../../apis';
const axios = require('axios').default;

export const sendRequest = async (reqData, url) => {
    try {
        const authToken = await AuthService.getAuthToken();
        const req = {
            url: url,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${ authToken }`
            },
            data: reqData
        };
        const response = await axios(req);
        if (response.status == HttpStatus.Ok) {
            return response.data;
        }
        throw new Error(`Some error occured with status ${ response.status }`);
    } catch (error) {
        console.log(error);
        throw error;
    }
};
