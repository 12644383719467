import { UserRole } from '../enums/user-role';
export const memberListRole = [UserRole.Admin, UserRole['Ops Manager'], UserRole.Agent, UserRole['Building Manager'], UserRole.Business];
export const managerListRole = [UserRole.Admin, UserRole['Ops Manager'], UserRole['Building Manager'], UserRole.Business];
export const teamLeadListRole = UserRole['Lead Agent'];

export const COLLECTION = Object.freeze({
    ASSETS: 'assets',
    ASSETSMAP: 'assets_map',
    ASSETTYPES: 'asset_types',
    ASSETVERIFICATIONS: 'asset_verifications',
    BOROUGHS: 'boroughs',
    BUILDINGS: 'buildings',
    DISTRICTS: 'districts',
    DOCUMENTS: 'documents',
    LOCATIONS: 'locations',
    SLOTS: 'slots',
    TEAMS: 'teams',
    TIMESHEET: 'timesheet',
    USERS: 'users',
    VISITS: 'visits',
    VISITLOCATIONS: 'visit_locations',
    VISIT_ACTIVITIES: 'visit_activities',
    SETTINGS: 'settings',
    PURPOSES: 'purposes',
    DELETEDENTITIES: 'deleted_entities'
});
