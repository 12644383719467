export const AssetState = Object.freeze({
    Unverified: 0,
    InConflict: 1,
    AutoVerified: 2,
    ManualVerified: 3,
    Deferred: 4,
    ApprovedForServiceNowUpdate: 5,
    ReMapped: 6, // Same as Discarded
    SentToServiceNow: 7,
    Scanned: 8
});
