<template>
    <div id="body">
        <router-view :key="$route.path" />
    </div>
    <Toast position="top-center" />
    <ConfirmDialog style=" width: 40vw; white-space: pre-line "></ConfirmDialog>
</template>

<script>
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
    name: 'App',
    components: {
        Toast,
        ConfirmDialog
    },
    created() {
        document.body.style.backgroundColor = '#F8FAFF';
    }
};
</script>
