const passwordMinLength = 8;
const passwordMaxLength = 20;

export const toasterTime = 3000;
export const toasterTimeForLongMsg = 5000;
export const rows = 10;
export const rowPerPageOptions = [10, 20, 50];
export const doughnutBackgroundColor = ['#47d7ac', '#fbd872', '#f8485e'];
export const doughnutHoverBackgroundColor = ['#47d7ac', '#fbd872', '#f8485e'];
export const conflictKeysToIncludeForTextHighlight = ['typeId',
    'assetTag',
    'serialNumber',
    'manufacturer',
    'model',
    'Site'
];
export const hoverBorderWidth = 1;
export const borderWidth = 0;
export const web = 0;
export const login = 0;
export const logout = 1;
export const minSlotAndTeamMemberCount = 1;
export const maximumLength = 100;
export const minimumValue = 1;
export const NA = 'NA';
export const iiq = 'IIQ';
export const unknown = 'Unknown';
export const fieldDataUnknown = 'unknown';
export const UNDEFINED = 'undefined';
export const UNK = 'UNK';
export const errorTag = '---ERROR---';
export const checkboxOptionLimitMessage = 'You cannot select more than 10 options';
export const regexPassword = new RegExp(`^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{${ passwordMinLength },${ passwordMaxLength }}$`);
export const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const windowBufferHeight = 1;
export const caseSplitRegex =
    /([^A-Za-z0-9.$])|([A-Z])(?=[A-Z][a-z])|([^\-$.0-9])(?=\$?[0-9]+(?:\.[0-9]+)?)|([0-9])(?=[^.0-9])|([a-z])(?=[A-Z])/g;
export const dropDownForFields = ['physicalCondition', 'powersOn', 'operational', 'typeId', 'Site'];
export const getDropdownItems = ['physicalCondition', 'powersOn', 'operational', 'typeId'];
export const fieldsToDisplayWhenToggleBtnOff = ['typeId', 'assetTag', 'serialNumber', 'manufacturer', 'model', 'Site', 'locationId'];
export const automaticResolvedFields = ['roomNumber', 'floorNumber', 'powersOn', 'physicalCondition', 'operational', 'cartTag'];
export const otherManufacturer = 'otherManufacturer';
export const unassignedDistrict = '00';
export const boroughOther = 'O';
export const chunkSize = 10;
export const locationTreePrefix = Object.freeze({
    borough: 'borough#',
    district: 'district#',
    building: 'building#',
    location: 'location#'
});
export const sendEmailUrl = process.env.VUE_APP_SENDEMAIL_URL;
export const timeAnalysisUrl = process.env.VUE_APP_VISIT_ANALYSIS_URL;
export const jsonToExcelUrl = process.env.VUE_APP_JSON_TO_EXCEL_URL;
export const applyRulesUrl = process.env.VUE_APP_APPLY_RULES_URL;
export const assetsFilterUrl = process.env.VUE_APP_ASSETS_FILTER_URL;
export const tableIconColor = {
    gray: 'Grey',
    blue: 'Blue',
    limegreen: 'limegreen',
    red: 'red'
};
export const userActivities = {
    created: 'created',
    updated: 'updated',
    completed: 'completed',
    cancelled: 'cancelled',
    archived: 'archived',
    approveForSNOW: 'approveForSNOW'
};
export const maxSlotBookCount = 1;
export const slotColor = {
    available: '#47D7AC',
    bookedByCurrentUser: '#397ced59',
    bookedByOther: '#397ced59'
};
export const slotStatus = {
    available: 'available',
    bookedByCurrentUser: 'bookedByCurrentUser',
    bookedByOther: 'bookedByOther'
};
export const dataType = {
    Object: 'object',
    String: 'string'
};

export const visitStatusColor = {
    0: 'gray-background',
    1: 'amber-background',
    2: 'light-green-background',
    3: 'dark-pink-background'
};
export const sortOrder = {
    Ascending: 1,
    Descending: -1
};

export const filterMatchMode = {
    rangeOperator: ['>=', '<='],
    arrayContains: 'array-contains'
};

export const conflictResolutionConst = {
    selectedScan: 'selectedScan',
    mappedWith: 'mappedWith',
    autoResolved: 'autoResolved',
    manufacturer: 'manufacturer',
    Other: 'Other',
    Site: 'Site',
    locationId: 'locationId',
    buildingId: 'buildingId',
    resolved: 'resolved',
    typeId: 'typeId',
    inSnowAt: 'In SNOW At: ',
    resolvedAt: 'Resolved At: ',
    floorNumber: 'floorNumber',
    roomNumber: 'roomNumber',
    Identification: 'Identification',
    snowType: 'SNOWType'
};

export const localStorageKeys = {
    selectedVisit: 'selectedVisit',
    resolveConflictFilter: 'resolveConflictFilter',
    showNewAssets: 'showNewAssets'
};

export const selectionType = {
    single: 'single',
    multiple: 'multiple'
};

export const toasterType = {
    error: 'error',
    success: 'success'
};

export const messages = {
    dateValidations: {
        startDateRequired: 'Start date is required.',
        endDateRequired: 'End date is required.',
        endDateValidation: 'End date must be greater than start date.'
    },
    uploadingFileValidation: {
        uploadingFile: 'Uploading file. Please wait.',
        uploadingFileFailed: 'Failed to upload file.'
    },
    visitValidation: {
        visitCretedSuccess: 'Visit created successfully.',
        visitCreateFailed: 'Failed to create visit.',
        visitUpdateSuccess: 'Visit updated successfully.',
        visitUpdateFailed: 'Failed to update visit.',
        visitFetchingFailed: 'Failed to fetch visit.',
        visitsFetchingFailed: 'Failed to load data.',
        visitDeleteSuccess: 'Visit deleted successfully.',
        visitDeleteFailed: 'Failed to delete visit.',
        cancelStateConfirmation: {
            message: 'Discarded visit can not be further changed to any other state.\nAre you sure you want to update?',
            header: 'Confirmation'
        },
        deleteConfirmation: {
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation'
        },
        visitFinalizeConfirmation: 'Would you like to finalize this visit?<br>This action is not revertible in future.',
        visitFinalizeSuccess: 'Visit is closed successfully.',
        visitFinalizeFailed: 'Failed to close visit.',
        visitApprovedSuccess: 'Visit certified successfully.',
        visitApprovedFailed: 'Visit failed to be certified.',
        visitEditedSuccess: 'Visit modified successfully.',
        visitEditedFailed: 'Failed to modify visit.',
        visitNameExist: 'Visit Name already exist.',
        visitNameErrorMessage: 'Visit name is required.',
        boroughErrorMessage: 'Borough is required.',
        districtErrorMessage: 'District is required.',
        visitLocationErrorMessage: 'Visit Location is required.',
        visitLocationFetchingFailed: 'Failed to fetch Locations.',
        slotsFetchingFailed: 'Failed to fetch Slots.',
        primaryContactNameErrorMessage: 'Primary contact name is required.',
        primaryContactEmailErrorMessage: 'Primary contact email is required.',
        primaryContactPhoneErrorMessage: 'Primary contact phone is required.',
        primaryContactEmailInvalid: 'Enter valid email address.',
        duplicateVisitExistForVisitLocationMessage:
            'Visit already exists for selected visit location and purpose. Please select another visit location or purpose.',
        bookedVisitCannotDelete: 'Only draft and booked visit can be deleted.',
        applyRuleApiErrored: 'Some error occurred during processing visit.',
        preparingVisitDataErrored: 'Some error occurred while preparing visit data.'
    },
    visitLocationValidation: {
        buildingErrorMessage: 'Building is required.',
        coveredLocationErrorMessage: 'At least one Covered Location is required',
        purposeErrorMessage: 'At least one purpose is required',
        visitLocationNameErrorMessage: 'Visit Location name is required.',
        visitLocationFetchingFailed: 'Failed to load data.',
        visitLocationCreatedSuccess: 'Visit Location created successfully.',
        visitLocationCreateFailed: 'Failed to create visit location.',
        visitLocationUpdateSuccess: 'Visit Location updated successfully.',
        visitLocationUpdateFailed: 'Failed to update visit location.',
        validEmailsRequired: 'Valid emails are required',
        visitLocationDeleteSuccess: 'Visit Location deleted successfully',
        visitLocationDeleteFailed: 'Failed to delete Visit Location.',
        visitLocationAssigned: 'Current Visit Location is assigned with a Visit',
        deleteConfirmation: {
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation'
        }
    },
    visitLocationSpocValidation: {
        spocNameErrorMessage: 'SPOC name is required.',
        spocEmailErrorMessage: 'SPOC email is required.',
        spocPhoneErrorMessage: 'SPOC phone is required.',
        spocEmailInvalid: 'Enter valid email address.'
    },
    documentValidation: {
        documentCretedSuccess: 'Document created successfully.',
        documentCreateFailed: 'Failed to create document.',
        documentUpdateSuccess: 'Document updated successfully.',
        documentUpdateFailed: 'Failed to update document.',
        documentsFetchingFailed: 'Failed to fetch document.',
        documentFetchingFailed: 'Failed to load data.',
        documentDeleteSuccess: 'Document deleted successfully.',
        documentDeleteFailed: 'Failed to delete document.',
        documentNameAlreadyExist: 'Document Name already exist',
        deleteConfirmation: {
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation'
        },
        documentRequired: 'Document Name is required.',
        documentLength: 'Document Name should not be greater than 50 characters.',
        fileError: 'Please browse a file.',
        locationTreeValidation: 'Please select atleast one location.',
        filterError: 'Cannot filter documents using Type and Location filter simultaneously.'
    },
    slotValidation: {
        slotsFetchingFailed: 'Failed to load data.',
        slotCretedSuccess: 'Slot created successfully.',
        slotCreateFailed: 'Failed to create slot.',
        slotDeleteSuccess: 'Slot deleted successfully.',
        slotDeleteFailed: 'Failed to delete slot.',
        slotFetchingFailed: 'Failed to fetch slot.',
        slotUpdateSuccess: 'Slot updated successfully.',
        slotUpdateFailed: 'Failed to update slot.',
        slotNameErrorMessage: 'Slot Name is required.',
        slotDurationErrorMessage: 'Slot Duration is required.',
        buildingCategoryErrorMessage: 'Building Category is required.',
        teamErrorMessage: 'Team is required.',
        temDataFetchingFailed: 'Failed to fetch team data.',
        slotNameCharacterLimit: 'Slot name should not contain more than 100 characters.',
        slotNameAlreadyExist: 'Slot name already exisit.',
        spaceAtBeginningError: 'Please remove space from beginning.',
        durationRangeError: 'Duration should be in range 1 to ',
        durationRequired: 'Duration is required',
        startDateEqualEndDate: 'Duration should be 1',
        minSlotCount: 'Minimum slot count should be 1.',
        slotCountRequired: 'Slot count is required.',
        teamMemberRequired: 'Team members is required.',
        minTeamMember: 'Member count should atleast be 1.',
        deleteConfirmation: {
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation'
        }
    },
    teamValidation: {
        teamsFetchingFailed: 'Failed to load data.',
        teamDeleteSuccess: 'Team deleted successfully.',
        teamDeleteFailed: 'Failed to delete team.',
        teamsLeadListFetchingFailed: 'Failed to fetch team lead list.',
        memberListFetchingFailed: 'Failed to fetch members list.',
        teamCretedSuccess: 'Team created successfully.',
        teamCreateFailed: 'Failed to create team.',
        teamNameErrorMessage: 'Team Name is Required.',
        teamLeadErrorMessage: 'Please select a team lead.',
        membersErrorMessage: 'Please select members.',
        teamUpdateSuccess: 'Team Updated successfully.',
        teamUpdateFailed: 'Failed to update team.',
        teamNameCharacterLimit: 'Team name should not contain more than 100 characters.',
        teamNameAlreadyExist: 'Team name already exist.',
        spaceAtBeginningError: 'Please remove space from beginning.',
        teamMemberRequired: 'Please select atleast one team member.',
        deleteConfirmation: {
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation'
        }
    },
    purposeValidation: {
        purposeFetchFailed: 'Failed to load data.',
        purposeCreateSuccess: 'Purpose created successfully.',
        purposeCreateFailed: 'Failed to create purpose.',
        purposeDeleteSuccess: 'Purpose deleted successfully.',
        purposeDeleteFailed: 'Failed to delete purpose.',
        purposeFetchingFailed: 'Failed to fetch purpose.',
        purposeUpdateSuccess: 'Purpose updated successfully.',
        purposeUpdateFailed: 'Failed to update purpose.',
        purposeNameAlreadyExists: 'Purpose name already exists.',
        purposeNameRequired: 'Purpose name is required.',
        purposeNameCharacterLimit: 'Purpose name should not contain more than 100 characters.',
        purposeDurationRequired: 'Purpose duration is required.',
        purposeMinDuration: 'Purpose duration must be greater than 0.',
        purposeAssetTypesRequired: 'Select atleast one asset type.'
    },
    scheduleValidation: {
        scheduleFetchingFailed: 'Failed to load data.',
        slotBookingFailed: 'Failed to book this slot.',
        slotCanNotBeReleased: 'A visit is already scheduled for this purpose. If you want to reschedule please contact {emailId}.',
        pastSlotBooking: 'You can not book past slot.',
        slotBookedByCurrentUser: 'You have already booked this slot.',
        slotBookedSuccess: 'Slot confirmed.',
        slotReleasedSuccess: 'Slot released successfully.',
        slotReleasingFailed: 'Failed to release this slot.',
        slotBoolMaxLimitReached: 'You can book maximum {maxSlotBookCount} slots for {visitLocationName}',

        slotNotAvailable: {
            message: 'This Slot is no longer available.',
            header: 'Information'
        },
        slotBookingConfirmation: {
            message: 'This will schedule {slotDuration} days slot for: {slotName}.,Are you sure?',
            header: 'Confirmation'
        },
        slotReleaseConfirmation: {
            message: 'Are you sure you want to release {slotName} slot?',
            header: 'Confirmation'
        },
        slotReScheduleConfirmation: {
            message: 'This will reschedule from {X} days slot starting {start} to {Y} days slot starting {startDateNow}.\nAre you sure?',
            header: 'Confirmation'
        }
    },
    slotEmail: {
        // eslint-disable-next-line max-len
        scheduleSlot: { body: '<p>Please note <b>{days}</b> days slot dated <b>{startDate} - {endDate}</b> has been booked for these  <b>{coveredLocations}</b> covered location. <br><br>Regards,<br>IIQ Inventory </p>', subject: 'Scheduled-' },
        // eslint-disable-next-line max-len
        releaseSlot: { body: '<p>Please note <b>{days}</b> days slot dated <b>{startDate} - {endDate}</b> has been cancelled. <br><br>Regards,<br>IIQ Inventory </p>', subject: 'Cancelled-' },
        // eslint-disable-next-line max-len
        rescheduleSlot: { body: '<p>Please note <b>{days}</b> days slot dated <b>{startDate} - {endDate}</b> has been rescheduled for these  <b>{coveredLocations}</b> covered location. <br><br>Regards,<br>IIQ Inventory </p>', subject: 'Rescheduled-' }
    },
    userValidation: {
        userUpdateSuccess: 'User updated Successfully.',
        userUpdateFailed: 'Error occurred while updating user.'
    },
    timesheetsValidation: {
        timesheetsFetchingFailed: 'Failed to load data.'
    },
    registrationValidation: {
        nameRequired: 'Please enter name.',
        emailRequired: 'Please enter email.',
        emailInvalid: 'Please enter valid email.',
        passwordRequired: 'Please enter password',
        passwordCondition: `Password must contain ${ passwordMinLength } letters with 1 digit, 1 lowercase and 1 uppercase character.`,
        confirmPassword: 'Please re-enter password.',
        emailExist: 'Email already registered.',
        errorOccurred: 'Error occurred in register.'
    },
    forgotPasswordValidation: {
        wrongEmail: 'The email is wrong.',
        emailNotExist: 'This email does not exists.',
        errorOccurred: 'Some error occurred.'
    },
    loginValidation: {
        invalidEmail: 'Invalid Email.',
        userNotFound: 'User not found.',
        passwordIncorrect: 'Password is incorrect.',
        userDisabled: 'This user has been disabled.',
        invalidLoginCredential: 'Invalid Login Credential.'
    },
    changePasswordValidation: {
        passwordNotMatched: 'Password do not match !',
        loginAgain: 'Please Login again to change a password.'
    },
    assetValidation: {
        filterError: 'Cannot filter assets using more than 1 Asset Type and more than 1 building simultaneously.'
    },
    deferredDialogText: {
        addReason: 'Add Deferred Reason',
        editReason: 'Edit Deferred Reason'
    },
    schoolListError: 'Failed to fetch school list.',
    teamListError: 'Failed to fetch team list.',
    slotListError: 'Failed to fetch Slots.',
    buildingCategoryListError: 'Failed to fetch location category list.',
    visitAnalysisError: 'Failed to fetch visit data'
};

// Message individual export so that we can utilize tree shaking
export const msgConflictResolution = {
    errorResolve: { severity: 'error', detail: 'Error in resolving conflicts.' },
    successResolve: { severity: 'success', detail: 'Conflict marked as resolved.' }
};

export const allText = {
    all: 'All'
};
export const noneText = {
    none: 'None'
};
export const assetVerificationKey = {
    operational: 'operational',
    physicalCondition: 'physical_condition',
    poweredOn: 'powered_on'
};

export const imageDimensions = {
    dimensions: '_864x864.jpg'
};
