import firebaseApp from '../../firebase';
import store from '../shared/store';
import { COLLECTION, Navigations, RoleNavigations } from '../shared/constants';
import { UserState } from '../shared/enums';
import { BaseService } from './';
import { SettingsService } from './';
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    getIdToken,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    confirmPasswordReset,
    verifyPasswordResetCode
} from 'firebase/auth';

const auth = getAuth(firebaseApp);

class AuthService extends BaseService {
    constructor() {
        super(COLLECTION.USERS);
    }
    async getAppSettings() {
        const settings = await SettingsService.getById('prefs');
        return settings;
    }
    async login(email, password) {
        const user = await signInWithEmailAndPassword(auth, email, password);
        store.dispatch('setUser', user.user);
        await this.setUserNavigations();
        return user;
    }

    async logout() {
        await signOut(auth);
        store.dispatch('setUser', null);
        store.commit('setUserDetails', null);
        store.dispatch('setNavigations', { roleNavigationIds: [], navigations: [] });
        store.commit('setLandingPage', '/');
    }

    async register(email, password) {
        return await createUserWithEmailAndPassword(auth, email, password);
    }

    async forgetPassword(email) {
        await sendPasswordResetEmail(auth, email);
    }

    async resetPassword(oobCode, newPassword) {
        await confirmPasswordReset(auth, oobCode, newPassword);
    }
    async verifyPasswordResetLink(oobCode) {
        return await verifyPasswordResetCode(auth, oobCode);
    }
    getCurrentUser() {
        return new Promise((resolve, reject) => {
            if (auth.currentUser) {
                resolve(auth.currentUser);
            } else {
                const removeListener = onAuthStateChanged(auth,
                    user => {
                        removeListener();
                        store.dispatch('setUser', user);
                        this.setUserNavigations().then(
                            () => resolve(user),
                            () => resolve(user)
                        );
                    },
                    err => {
                        reject(err.message);
                    }
                );
            }
        });
    }
    async setUserNavigations() {
        store.commit('setAppSettings', await this.getAppSettings());

        if (!store.getters.userDetails) {
            const userInfo = await this.getById(store.getters.getSessionUser.uid);
            store.commit('setUserDetails', userInfo);
        }
        if (store.getters.userDetails?.state == UserState.Active && store.getters.navigations.length === 0) {
            const { landingPage, navigations } = RoleNavigations[store.getters.userDetails.role];
            store.dispatch('setNavigations', { navigations: Navigations, roleNavigationIds: navigations });
            store.dispatch('setLandingPage', landingPage);
        }
    }
    async passwordUpdate(newPassword) {
        return await updatePassword(auth.currentUser, newPassword);
    }

    async getAuthToken() {
        const token = await getIdToken(auth.currentUser);
        return token;
    }
}
export default new AuthService();
