import { NavigationType } from '../enums';

export const USER_ROLE = Object.freeze({
    ADMIN: 1,
    USER: 2,
    OPS_MANAGER: 3,
    BACK_OFFICE_MANAGER: 4,
    DATA_MANAGER: 5,
    LEAD_AGENT: 6,
    AGENT: 7,
    BUILDING_MANAGER: 8,
    BUSINESS: 9
});

export const NAVIGATIONCONST = {
    ASSETS: 'Assets',
    ALLVISITS: 'AllVisits',
    USERSANDACCESSCONTROL: 'UsersAndAccessControl',
    OURVISITS: 'OurVisits',
    VIEWVISIT: 'ViewVisit',
    CREATEVISIT: 'CreateVisit',
    EDITVISIT: 'EditVisit',
    CREATENEWTEAM: 'CreateNewTeam',
    DELETETEAM: 'DeleteTeam',
    UPDATETEAM: 'UpdateTeam',
    CREATENEWSLOT: 'CreateNewSlot',
    DELETESLOT: 'DeleteSlot',
    UPDATESLOT: 'UpdateSlot',
    ADDVISITLOCATION: 'AddVisitLocation',
    DELETEVISITLOCATION: 'DeleteVisitLocation',
    EDITVISITLOCATION: 'EditVisitLocation',
    VIEWVISITLOCATION: 'ViewVisitLocation',
    OURASSETS: 'OurAssets',
    RESOLVECONFLICT: 'ResolveConflict',
    RESOLVECONFLICTS: 'ResolveConflicts',
    RESOLVESCANCONFLICTS: 'ResolveScanConflicts',
    HOME: 'Home',
    VISITANALYSIS: 'VisitAnalysis',
    CREATENEWPURPOSE: 'CreateNewPurpose',
    DELETEPURPOSE: 'DeletePurpose',
    UPDATEPURPOSE: 'UpdatePurpose',
    PURPOSES: 'Purposes',
    CHANGEPASSWORD: 'ChangePassword',
    TODAYSVISITS: 'TodaysVisits',
    PAGENOTFOUND: 'PageNotFound'
};

export const RoleNavigations = {
    [USER_ROLE.ADMIN]: {
        landingPage: '/visits',
        navigations: [
            'Home',
            'schedule',
            'Visits',
            'Timesheets',
            'ActivitiesLog',
            NAVIGATIONCONST.ASSETS,
            'Setup',
            NAVIGATIONCONST.RESOLVECONFLICT,
            NAVIGATIONCONST.RESOLVECONFLICTS,
            'AllSchedules',
            'ScheduleNewVisit',
            NAVIGATIONCONST.TODAYSVISITS,
            NAVIGATIONCONST.ALLVISITS,
            NAVIGATIONCONST.VISITANALYSIS,
            'AllAssets',
            'Documents',
            NAVIGATIONCONST.USERSANDACCESSCONTROL,
            'Teams',
            'Slots',
            NAVIGATIONCONST.CREATENEWTEAM,
            NAVIGATIONCONST.UPDATETEAM,
            NAVIGATIONCONST.DELETETEAM,
            NAVIGATIONCONST.CREATENEWSLOT,
            NAVIGATIONCONST.UPDATESLOT,
            NAVIGATIONCONST.DELETESLOT,
            NAVIGATIONCONST.VIEWVISIT,
            'addVisit',
            'Locations',
            NAVIGATIONCONST.ADDVISITLOCATION,
            NAVIGATIONCONST.DELETEVISITLOCATION,
            NAVIGATIONCONST.EDITVISITLOCATION,
            NAVIGATIONCONST.VIEWVISITLOCATION,
            NAVIGATIONCONST.CREATEVISIT,
            NAVIGATIONCONST.EDITVISIT,
            NAVIGATIONCONST.CHANGEPASSWORD,
            NAVIGATIONCONST.PURPOSES,
            NAVIGATIONCONST.CREATENEWPURPOSE,
            NAVIGATIONCONST.UPDATEPURPOSE,
            NAVIGATIONCONST.DELETEPURPOSE,
            NAVIGATIONCONST.PAGENOTFOUND
        ]
    },
    [USER_ROLE.USER]: { landingPage: 'PendingApproval', navigations: [NAVIGATIONCONST.CHANGEPASSWORD] },
    [USER_ROLE.OPS_MANAGER]: {
        landingPage: '/visits',
        navigations: [
            'Home',
            'Visits',
            'schedule',
            'AllSchedules',
            'ScheduleNewVisit',
            'addVisit',
            NAVIGATIONCONST.VIEWVISIT,
            NAVIGATIONCONST.EDITVISIT,
            NAVIGATIONCONST.TODAYSVISITS,
            'Timesheets',
            'ActivitiesLog',
            NAVIGATIONCONST.ASSETS,
            'Setup',
            'Slots',
            'AllAssets',
            NAVIGATIONCONST.RESOLVECONFLICT,
            NAVIGATIONCONST.RESOLVECONFLICTS,
            'Teams',
            'Documents',
            NAVIGATIONCONST.CREATENEWTEAM,
            NAVIGATIONCONST.DELETETEAM,
            NAVIGATIONCONST.UPDATETEAM,
            NAVIGATIONCONST.CREATENEWSLOT,
            NAVIGATIONCONST.UPDATESLOT,
            NAVIGATIONCONST.DELETESLOT,
            NAVIGATIONCONST.ALLVISITS,
            'Locations',
            NAVIGATIONCONST.CREATEVISIT,
            NAVIGATIONCONST.ADDVISITLOCATION,
            NAVIGATIONCONST.DELETEVISITLOCATION,
            NAVIGATIONCONST.EDITVISITLOCATION,
            NAVIGATIONCONST.VIEWVISITLOCATION,
            NAVIGATIONCONST.CHANGEPASSWORD,
            NAVIGATIONCONST.PURPOSES,
            NAVIGATIONCONST.CREATENEWPURPOSE,
            NAVIGATIONCONST.UPDATEPURPOSE,
            NAVIGATIONCONST.DELETEPURPOSE
        ]
    },
    [USER_ROLE.BACK_OFFICE_MANAGER]: {
        landingPage: '/visits',
        navigations: [
            NAVIGATIONCONST.ALLVISITS,
            NAVIGATIONCONST.VIEWVISIT,
            'Home',
            'Visits',
            'schedule',
            'AllSchedules',
            NAVIGATIONCONST.TODAYSVISITS,
            'AllVisits',
            'Timesheets',
            'Locations',
            NAVIGATIONCONST.VIEWVISITLOCATION,
            NAVIGATIONCONST.CHANGEPASSWORD
        ]
    },
    [USER_ROLE.DATA_MANAGER]: {
        landingPage: '/resolveConflicts',
        navigations: ['Home', NAVIGATIONCONST.ASSETS, 'AllAssets', NAVIGATIONCONST.RESOLVECONFLICTS, NAVIGATIONCONST.CHANGEPASSWORD]
    },
    [USER_ROLE.LEAD_AGENT]: { landingPage: 'myVisits', navigations: ['Visits', NAVIGATIONCONST.VIEWVISIT,
        'myVisits', 'MyTimesheets', NAVIGATIONCONST.CHANGEPASSWORD] },
    [USER_ROLE.AGENT]: { landingPage: 'myVisits', navigations: ['Visits', NAVIGATIONCONST.VIEWVISIT,
        'myVisits', 'MyTimesheets', NAVIGATIONCONST.CHANGEPASSWORD] },
    [USER_ROLE.BUILDING_MANAGER]: {
        landingPage: '/scheduleNewVisit',
        navigations: [
            // 'OurSchedules',
            'ScheduleNewVisit',
            // 'schedule',
            // 'Home',
            // 'Visits',
            // NAVIGATIONCONST.OURVISITS,
            // 'Timesheets',
            // NAVIGATIONCONST.OURASSETS,
            // 'OurActivitiesLog',
            // NAVIGATIONCONST.ASSETS,
            // NAVIGATIONCONST.RESOLVECONFLICT,
            // NAVIGATIONCONST.RESOLVESCANCONFLICTS,
            // NAVIGATIONCONST.VIEWVISIT,
            // 'Locations',
            // NAVIGATIONCONST.VIEWVISITLOCATION,
            NAVIGATIONCONST.CHANGEPASSWORD
        ]
    },
    [USER_ROLE.BUSINESS]: {
        landingPage: '/visits',
        navigations: [
            'Home',
            'Visits',
            'schedule',
            NAVIGATIONCONST.ASSETS,
            NAVIGATIONCONST.ALLVISITS,
            NAVIGATIONCONST.VIEWVISIT,
            'Timesheets',
            'Slots',
            'Setup',
            'AllSchedules',
            'Visit',
            NAVIGATIONCONST.TODAYSVISITS,
            'AllVisits',
            'ActivitiesLog',
            'AllAssets',
            'Locations',
            NAVIGATIONCONST.VIEWVISITLOCATION,
            NAVIGATIONCONST.CHANGEPASSWORD
        ]
    }
};

export const Navigations = [
    {
        id: NAVIGATIONCONST.CREATEVISIT,
        displayName: 'Create Visit',
        order: 1,
        type: NavigationType.UserAction
    },
    {
        id: 'myVisits',
        to: '/myVisits',
        displayName: 'My Visits',
        order: 4,
        type: NavigationType.SubMenu,
        parentId: 'Visits'
    },
    {
        id: 'Locations',
        to: '/locations',
        displayName: 'Locations',
        order: 1,
        type: NavigationType.SubMenu,
        parentId: 'Visits'
    },
    { id: NAVIGATIONCONST.CREATENEWTEAM, type: NavigationType.UserAction, displayName: 'Create New Team', order: '1' },
    /* {
        id: 'AllSchedules',
        to: '/allSchedules',
        displayName: 'All Schedules',
        order: 1,
        type: NavigationType.SubMenu,
        parentId: 'schedule'
    }, */
    { id: NAVIGATIONCONST.UPDATESLOT, displayName: 'Update Slot', type: NavigationType.UserAction, order: '1' },
    // { id: NAVIGATIONCONST.UPDATEPURPOSE, displayName: 'Update Purpose', type: NavigationType.UserAction, order: '1' },
    { id: 'PendingApproval', displayName: 'Pending Approval', to: '/pendingApproval', order: '1' },
    { id: NAVIGATIONCONST.UPDATETEAM, order: '1', displayName: 'Update Team', type: NavigationType.UserAction },
    /* {
        id: 'OurActivitiesLog',
        order: 6,
        displayName: 'Our Activities Log',
        type: NavigationType.SubMenu,
        parentId: 'Visits',
        to: '/ourActivitiesLog'
    },*/
    {
        id: 'OurVisits',
        order: 3,
        type: NavigationType.SubMenu,
        to: '/ourVisits',
        displayName: 'Our Visits',
        parentId: 'Visits'
    },
    { id: NAVIGATIONCONST.EDITVISIT, type: NavigationType.UserAction, displayName: 'Edit Visit' },
    { id: NAVIGATIONCONST.EDITVISITLOCATION, type: NavigationType.UserAction, displayName: 'Edit Visit Location' },
    { id: NAVIGATIONCONST.DELETEVISITLOCATION, type: NavigationType.UserAction, displayName: 'Delete Visit Location' },
    {
        id: 'AllAssets',
        parentId: NAVIGATIONCONST.ASSETS,
        to: '/assets',
        type: NavigationType.SubMenu,
        order: 1,
        displayName: 'All Assets'
    },
    {
        id: 'Documents',
        type: NavigationType.SubMenu,
        parentId: 'Setup',
        to: '/documents',
        displayName: 'Documents',
        order: 3
    },
    { id: NAVIGATIONCONST.VIEWVISIT, type: NavigationType.UserAction, displayName: 'View Visit', order: '1', parentId: 'Visits' },
    { id: 'AddVisitLocation', displayName: 'Add Visit Location', type: 'Add' },
    // { id: 'Home', type: NavigationType.MainMenu, to: '/', order: 1, displayName: 'Home' },
    /* {
        id: 'MyTimesheets',
        type: NavigationType.SubMenu,
        order: 8,
        displayName: 'My Timesheets',
        parentId: 'Visits',
        to: '/mytimesheets'
    }, */
    {
        id: NAVIGATIONCONST.RESOLVECONFLICTS,
        parentId: NAVIGATIONCONST.ASSETS,
        order: 3,
        to: '/resolveConflicts',
        type: NavigationType.SubMenu,
        displayName: 'Resolve Conflicts'
    },
    /* {
        id: 'Timesheets',
        order: '7',
        parentId: 'Visits',
        type: NavigationType.SubMenu,
        to: '/timesheets',
        displayName: 'Timesheets'
    }, */
    { id: NAVIGATIONCONST.CREATENEWSLOT, type: NavigationType.UserAction, displayName: 'Create New Slot', order: '1' },
    { id: NAVIGATIONCONST.DELETESLOT, displayName: 'Delete Slot', type: NavigationType.UserAction, order: '1' },
    /* { id: NAVIGATIONCONST.CREATENEWPURPOSE, type: NavigationType.UserAction, displayName: 'Create New Purpose', order: '1' },
    { id: NAVIGATIONCONST.DELETEPURPOSE, displayName: 'Delete Purpose', type: NavigationType.UserAction, order: '1' },*/
    { id: NAVIGATIONCONST.ASSETS, displayName: NAVIGATIONCONST.ASSETS, order: 4, type: NavigationType.MainMenu },
    /* {
        id: 'ActivitiesLog',
        order: 5,
        displayName: 'Activities Log',
        parentId: 'Visits',
        to: '/activityLog',
        type: NavigationType.SubMenu
    }, */
    { id: 'addVisit', displayName: 'Create Visit', type: NavigationType.UserAction },
    {
        id: NAVIGATIONCONST.USERSANDACCESSCONTROL,
        displayName: 'Users & Access Control',
        to: '/users',
        parentId: 'Setup',
        order: 4,
        type: NavigationType.SubMenu
    },
    {
        id: NAVIGATIONCONST.RESOLVESCANCONFLICTS,
        parentId: NAVIGATIONCONST.ASSETS,
        displayName: 'Resolve Scan Conflicts',
        type: NavigationType.SubMenu,
        order: 4,
        to: '/resolveScanConflicts'
    },
    { id: 'Visits', order: 3, displayName: 'Visit', type: NavigationType.MainMenu },
    {
        id: 'OurAssets',
        displayName: 'Our Assets',
        type: NavigationType.SubMenu,
        parentId: NAVIGATIONCONST.ASSETS,
        order: 2,
        to: '/ourassets'
    },
    {
        id: 'AllVisits',
        type: NavigationType.SubMenu,
        to: '/visits',
        parentId: 'Visits',
        order: 2,
        displayName: 'All Visits'
    },
    {
        id: 'OurSchedules',
        displayName: 'Our Schedules',
        parentId: 'schedule',
        type: NavigationType.SubMenu,
        order: 2,
        to: '/ourSchedules'
    },
    {
        id: 'ScheduleNewVisit',
        type: NavigationType.SubMenu,
        displayName: 'Schedule New Visit',
        order: 3,
        to: '/scheduleNewVisit',
        parentId: 'schedule'
    },
    { id: NAVIGATIONCONST.DELETETEAM, order: '1', displayName: 'Delete Team', type: NavigationType.UserAction },
    { id: 'schedule', displayName: 'Schedule', order: 2, type: NavigationType.MainMenu },
    {
        id: NAVIGATIONCONST.TODAYSVISITS,
        to: '/todaysVisit',
        parentId: 'Visits',
        displayName: 'Today\'s Visits',
        order: 1,
        type: NavigationType.SubMenu
    },
    {
        id: 'Teams',
        displayName: 'Teams',
        type: NavigationType.SubMenu,
        order: '1',
        parentId: 'Setup',
        to: '/teams'
    },
    { id: 'Setup', order: 5, type: NavigationType.MainMenu, displayName: 'Setup' },
    {
        id: 'Slots',
        type: NavigationType.SubMenu,
        order: 2,
        to: '/slots',
        parentId: 'Setup',
        displayName: 'Slots'
    },
    { id: NAVIGATIONCONST.VISITANALYSIS, type: NavigationType.UserAction, displayName: 'Visit Analysis' }
    /* ,
    {
        id: NAVIGATIONCONST.PURPOSES,
        type: NavigationType.SubMenu,
        order: 5,
        to: '/purposes',
        parentId: 'Setup',
        displayName: 'Purposes'
    }*/
];
