import { BaseService } from '.';
import { COLLECTION } from '../shared/constants';

class AssetTypeService extends BaseService {
    constructor() {
        super(COLLECTION.ASSETTYPES);
    }
}

export default new AssetTypeService();
