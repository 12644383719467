import { COLLECTION } from '../shared/constants';
import { BaseService } from './';
import { firestore } from '../../firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

class DeletedEntitiesService extends BaseService {
    constructor() {
        super(COLLECTION.DELETEDENTITIES);
    }

    async updateDeletedEntities(id, data, entityName) {
        const docRef = doc(firestore, this.collectionName, id);
        await updateDoc(docRef, { [entityName]: arrayUnion(data) });
    }
}
export default new DeletedEntitiesService();
