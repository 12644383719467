import { firestore } from '../../firebase';
import { UserState } from '../shared/enums';
import { BaseService } from './';
import { teamLeadListRole, COLLECTION } from '../shared/constants';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { UserRole } from '../shared/enums/user-role';

class UserService extends BaseService {
    constructor() {
        super(COLLECTION.USERS);
    }

    async updateLoginLogoutDateTime(data, uid) {
        const docRef = doc(firestore, this.collectionName, uid);
        await updateDoc(docRef, { logins: arrayUnion(data) });
    }

    async getTeamLeadList() {
        return this.getSnapshot({
            state: { matchMode: '==', value: UserState.Active },
            role: { matchMode: '==', value: teamLeadListRole }
        });
    }

    async getMemberList(memberListRole) {
        return this.getSnapshot({
            state: { matchMode: '==', value: UserState.Active },
            role: { matchMode: 'in', value: memberListRole }
        });
    }

    async getBuildingManagerVisitLocations(uid) {
        const userData = await this.getById(uid);
        return await userData.roleData?.[UserRole['Building Manager']];
    }

    async updateRoleData(uid, data) {
        const fieldId = `roleData.${ UserRole['Building Manager'] }`;
        const docRef = doc(firestore, this.collectionName, uid);
        await updateDoc(docRef, { [fieldId]: arrayUnion(data) });
    }

    async deleteRoleData(uid, data) {
        const fieldId = `roleData.${ UserRole['Building Manager'] }`;
        const docRef = doc(firestore, this.collectionName, uid);
        await updateDoc(docRef, { [fieldId]: arrayRemove(data) });
    }

    async getRoleData(visitLocationId) {
        const fieldId = `roleData.${ UserRole['Building Manager'] }`;
        return this.getAll({
            [fieldId]: { matchMode: 'array-contains', value: visitLocationId }
        });
    }
}
export default new UserService();
