import { BaseService } from './';
import { COLLECTION, chunkSize } from '../shared/constants';
import { VisitStatus, AssetState } from '../shared/enums';

class VisitService extends BaseService {
    constructor() {
        super(COLLECTION.VISITS);
    }

    async getVisitBySlotId(slotIds) {
        const chunkSize = 10;
        slotIds = [...new Set(slotIds)];
        const promises = [];
        for (let i = 0; i < slotIds.length; i += chunkSize) {
            promises.push(
                this.getAll([
                    { 'schedule.slotId': { matchMode: 'in', value: slotIds } }
                ])
            );
        }
        const result = await Promise.all(promises);
        return result.flat();
    }

    async checkVisitNameExist(visitName, id) {
        const filters = { title: { matchMode: '==', value: visitName } };
        if (id) {
            filters.DOCUMENT_ID = {
                value: id,
                matchMode: '!='
            };
        }
        const { size } = await this.get({ filters });
        return size;
    }

    async checkUniqueVisitLocationSlotTypeExist(boroughId, districtId, visitLocationId, requiredSlotId) {
        const res = await this.getAll({
            filters: {
                'visitLocationInfo.boroughId': { value: boroughId, matchMode: '==' },
                'visitLocationInfo.districtId': { value: districtId, matchMode: '==' },
                'visitLocationId': { value: visitLocationId, matchMode: '==' },
                'purposeId': { value: requiredSlotId, matchMode: '==' }
            }
        });
        return res;
    }
    async getVisitsByBuildingId(buildings, statusList) {
        buildings = [...new Set(buildings)];
        const promises = [];
        for (const status of statusList) {
            for (let i = 0; i < buildings.length; i += chunkSize) {
                promises.push(
                    this.getAll({
                        'visitLocationInfo.buildingId': { matchMode: 'in', value: buildings.slice(i, i + chunkSize) },
                        'state': { matchMode: '==', value: status }
                    })
                );
            }
        }
        const result = await Promise.all(promises);
        return result.flat();
    }

    async getVisitByStatusList(statusList) {
        const visits = await this.getAll({ 'state': { matchMode: 'in', value: statusList } } );
        return visits;
    }

    async getVisitByAssetFilter(filter) {
        const statusList = [VisitStatus.Completed];
        if (filter) {
            if (filter.state?.value == AssetState.Deferred) {
                statusList.push(VisitStatus.ApproveForSNOW);
            }
            if (filter['original.buildingId']?.value?.length > 0) {
                return this.getVisitsByBuildingId(filter['original.buildingId'].value, statusList);
            }
        }
        return this.getVisitByStatusList(statusList);
    }
}

export default new VisitService();
