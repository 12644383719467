import { ref, onUnmounted } from 'vue';
import { firestore } from '../../firebase';
import { InParamMaxSize, getQueryAfterFilter, insertDeletedEntity } from '../shared/utils';
import store from '../shared/store';
import {
    collection,
    query,
    getDoc,
    doc,
    setDoc,
    orderBy,
    limit,
    getDocs,
    startAfter,
    onSnapshot,
    addDoc,
    deleteDoc
} from 'firebase/firestore';


export default class BaseService {
    constructor(collectionName) {
        this.collectionName = collectionName;
    }

    async get({ filters, first, rows = -1, sortField, sortOrder }, next = () => {}, result = null, uniqueMap = null) {
        let size = 0;
        let queryPageNumber = 0;
        let queryWithFilter = { executeOrQuery: false };
        do {
            queryWithFilter = getQueryAfterFilter(
                filters,
                query(collection(firestore, this.collectionName)),
                ++queryPageNumber,
                queryWithFilter.executeOrQuery
            );
            let { q, mustOrderBy, isFilterOnAny, hasRemainingSearch, shouldNotOrderByAny } = queryWithFilter;
            if (sortField && !shouldNotOrderByAny.includes(sortField)) {
                q = query(q, orderBy(mustOrderBy || sortField, sortOrder == 1 ? 'asc' : 'desc'));
            }

            if (first) {
                q = query(q, limit(first));
                const data = await getDocs(q);
                if (data.docs?.length) {
                    if (hasRemainingSearch && data.docs.length < first) {
                        first -= data.docs.length;
                        continue;
                    }
                    q = query(q, startAfter(data.docs[data.docs.length - 1]));
                }
            }

            if (rows > 0) {
                q = query(q, limit(rows - result.length));
            }

            const snapshot = await getDocs(q);
            size += snapshot.size;
            next(snapshot, isFilterOnAny);
        } while (queryWithFilter.hasRemainingSearch && result?.length < rows);
        return { size, result };
    }

    async getAll(
        filters,
        rows,
        result = [],
        next = (snapshot, isFilterOnAny) => {
            if (isFilterOnAny) {
                snapshot.forEach(doc => uniqueMap.set(doc.id, { id: doc.id, ...doc.data() }));
                result = Array.from(uniqueMap.values());
            } else {
                result.push(...snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            }
        },
        uniqueMap = new Map()
    ) {
        const response = await this.get(filters && filters['filters'] ? filters : { filters, rows }, next, result, uniqueMap);
        return response.result;
    }

    async getSnapshot(
        params,
        dataRef = ref([]),
        next = snapshot => {
            let timeoutId;
            snapshot.docChanges().forEach(change => {
                (change.type === 'added' || change.type === 'modified') &&
                    uniqueMap.set(change.doc.id, { id: change.doc.id, ...change.doc.data() });
                change.type === 'removed' && uniqueMap.delete(change.doc.id);
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(() => (dataRef.value = Array.from(uniqueMap.values())), 50);
            });
        },
        uniqueMap = new Map()
    ) {
        let { filters, first, rows = -1, sortField, sortOrder } = params && params['filters'] ? params : { filters: params };
        let queryPageNumber = 0;
        let queryWithFilter = { executeOrQuery: false };
        do {
            queryWithFilter = getQueryAfterFilter(
                filters,
                query(collection(firestore, this.collectionName)),
                ++queryPageNumber,
                queryWithFilter.executeOrQuery
            );
            let { q, mustOrderBy, hasRemainingSearch, shouldNotOrderByAny } = queryWithFilter;
            if (sortField && !shouldNotOrderByAny.includes(sortField)) {
                q = query(q, orderBy(mustOrderBy || sortField, sortOrder == -1 ? 'desc' : 'asc'));
            }

            if (first) {
                q = query(q, limit(first));
                const data = await getDocs(q);
                if (data.docs?.length) {
                    if (hasRemainingSearch && data.docs.length < first) {
                        first -= data.docs.length;
                        continue;
                    }
                    q = query(q, startAfter(data.docs[data.docs.length - 1]));
                }
            }

            if (rows > 0 && rows - dataRef.value?.length > 0) {
                q = query(q, limit(rows - dataRef.value?.length));
            }
            const unsubscribe = onSnapshot(q, next);
            store.commit('setUnsubscribe', unsubscribe);
            onUnmounted(unsubscribe);
        } while (queryWithFilter.hasRemainingSearch && (dataRef.value?.length && rows != -1 ? dataRef.value?.length < rows : true));
        return dataRef;
    }

    async getSnapshotCount(filters) {
        const totalRecordsRef = ref(0);
        const uniqueSet = new Set();
        const next = snapshot => {
            let timeoutId;
            snapshot.docChanges().forEach(change => {
                if (change.type === 'modified') return;
                change.type === 'added' && uniqueSet.add(change.doc.id);
                change.type === 'removed' && uniqueSet.delete(change.doc.id);
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(() => (totalRecordsRef.value = uniqueSet.size), 50);
            });
        };
        return await this.getSnapshot(filters, totalRecordsRef, next, uniqueSet);
    }

    async getById(id) {
        const docRef = doc(firestore, this.collectionName, id);
        const response = await getDoc(docRef);
        if (response.exists()) {
            return response.data();
        } else {
            return null;
        }
    }

    async getByIds(ids) {
        ids = [...new Set(ids)];
        const promises = [];
        for (let i = 0; i < ids.length; i += InParamMaxSize) {
            promises.push(
                this.getAll({
                    DOCUMENT_ID: {
                        matchMode: 'in',
                        value: ids.slice(i, i + InParamMaxSize)
                    }
                })
            );
        }

        const result = await Promise.all(promises);
        return result.flat();
    }

    async create(data) {
        const collRef = collection(firestore, this.collectionName);
        const docRef = await addDoc(collRef, data);
        const id = docRef.id;
        return { id };
    }

    async delete(id) {
        await insertDeletedEntity(id, this.collectionName);
        await deleteDoc(doc(firestore, this.collectionName, id));
    }

    async update(id, data, allowMerge = true) {
        if (data.id) delete data.id;
        const mergeParam = { merge: allowMerge };
        const docRef = doc(firestore, this.collectionName, id);
        await setDoc(docRef, data, mergeParam);
    }
}
