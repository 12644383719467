import { BaseService } from './';
import { COLLECTION } from '../shared/constants';

class AssetMapService extends BaseService {
    constructor() {
        super(COLLECTION.ASSETSMAP);
    }

    async getSerialNumberMapping(serialNumber) {
        return await this.getAll({
            serialNumber: { matchMode: '==', value: serialNumber }
        });
    }

    async getAssetTagMapping(assetTag) {
        return await this.getAll({
            assetTag: { matchMode: '==', value: assetTag }
        });
    }
}

export default new AssetMapService();
