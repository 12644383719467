/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from 'primevue/config';
import router from "./shared/router";
import store from "./shared/store";
import Vue3Sanitize from "vue-3-sanitize";
import 'mdb-vue-ui-kit/css/mdb.min.css';
import "./override.css";
import './public/fonts.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'primevue/resources/themes/mdc-light-indigo/theme.css' // theme 
import 'primevue/resources/primevue.min.css' // core css
import 'primeicons/primeicons.css' // icons
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';


createApp(App)
.use(store)
.use(PrimeVue, {ripple: true})
.use(ToastService)
.use(ConfirmationService)
.use(Vue3Sanitize)
.use(router).mount("#app");
