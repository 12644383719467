import { createStore } from 'vuex';
import { routeNavigation } from '../router';

export default createStore({
    state: {
        isFilterOpen: false,
        pagination: 5,
        sessionUser: {},
        authenticated: false,
        navigations: [],
        userDetails: null,
        landingPage: '/',
        settings: {},
        unsubscribe: [],
        assetSortObj: {},
        conflictedAssets: []
    },
    getters: {
        getAppSettings: state => {
            return state.settings;
        },
        isFilterOpen: state => {
            return state.isFilterOpen;
        },
        getPagination: state => {
            return state.pagination;
        },
        getSessionUser: state => {
            return state.sessionUser;
        },
        isLogin: state => {
            return state.authenticated;
        },
        navigations: state => {
            return state.navigations;
        },
        userDetails: state => {
            return state.userDetails;
        },
        landingPage: state => {
            return state.landingPage;
        },
        getUnsubscribe: state => {
            return state.unsubscribe;
        },
        getAssetSortObj: state => {
            return state.assetSortObj;
        },
        getConflictedAsests: state => state.conflictedAssets
    },
    mutations: {
        setAppSettings(state, settings) {
            state.settings = settings;
        },
        toggleFilter(state) {
            state.isFilterOpen = !state.isFilterOpen;
        },
        updatePagination(state, paginationValues) {
            // if pagination values is about rowsPerPage
            if (paginationValues >= 5) {
                state.pagination = paginationValues;
            } else {
                // if pagination values is about moving page
                state.pagination = paginationValues;
            }
        },
        setAuthentication(state, login) {
            state.authenticated = login;
        },
        setSessionUser(state, user) {
            state.sessionUser = user;
        },
        setNavigations(state, navigationList) {
            state.navigations = navigationList;
        },
        setUserDetails(state, userInfo) {
            state.userDetails = userInfo;
        },
        setLandingPage(state, landingPage) {
            state.landingPage = landingPage;
        },
        setUnsubscribe(state, unsubscribe) {
            state.unsubscribe.push(unsubscribe);
        },
        setAssetSortObj(state, assetSortObj) {
            state.assetSortObj = assetSortObj;
        },
        setConflictedAssets(state, assets) {
            state.conflictedAssets = assets;
        }
    },
    actions: {
        setUser({ commit }, user) {
            commit('setAuthentication', user !== null);
            if (user) {
                commit('setSessionUser', user);
            } else {
                commit('setSessionUser', null);
            }
        },
        setNavigations({ commit, state }, { navigations, roleNavigationIds }) {
            const routeMap = new Map();
            routeNavigation.concat(navigations).forEach(r => routeMap.set(r.id, r));
            const allowedNavigations = Array.from(routeMap.values()).filter(x => roleNavigationIds.includes(x.id));
            commit('setNavigations', allowedNavigations);
        },
        setUserDetails({ commit }, userInfo) {
            commit('setUserDetails', userInfo);
        },
        setLandingPage({ commit }, landingPage) {
            commit('setLandingPage', landingPage);
        }
    },
    modules: {}
});
