import { BaseService } from './';
import { COLLECTION, iiq, unknown } from '../shared/constants';
import { firestore } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { ProviderState, AssetState, LastUpdatedSource } from '../shared/enums';
import { InParamMaxSize } from '../shared/utils';

class AssetService extends BaseService {
    constructor() {
        super(COLLECTION.ASSETS);
    }

    async update(id, data) {
        data.lastChanged = new Date();
        const docRef = doc(firestore, COLLECTION.ASSETS, id);
        await updateDoc(docRef, data);
    }

    async getAssetCount(assetIdArray) {
        const rows = {};
        const assetCount = [];
        const mappedAssetIds = [];
        const assetsDetail = await this.getByIds(assetIdArray.flat());
        const scanedAssets = assetsDetail.filter(asset => asset.scanned?.length);
        for (const x of scanedAssets) {
            const mapIds = x.verification?.physical?.verifiedWithResolvedConflicts?.map;
            if (mapIds?.length) {
                mappedAssetIds.push(mapIds);
                const res = await this.getByIds(mappedAssetIds.flat());
                scanedAssets.push(...res);
            }
        }
        scanedAssets.forEach(x => {
            let provider;
            let type;
            if (x.original && x.original.lastUpdated.source != LastUpdatedSource.FieldScan) {
                provider = x.original.provider ? x.original.provider : ProviderState.Unknown;
                type = x.original.SNOWTypeId ? x.original.SNOWTypeId : unknown;
            } else {
                provider = iiq;
                type = x.scanned[0].data.typeId ? x.scanned[0].data.typeId : unknown;
            }
            this.fillRows(rows, provider, type, x);
        });
        let unknownExist = false;
        for (const i of Object.keys(rows)) {
            if (i == ProviderState.Unknown) {
                unknownExist= true;
                continue;
            }
            for (const j of Object.keys(rows[i])) {
                if (!j) {
                    rows[i][j].type = unknown;
                }
                assetCount.push(rows[i][j]);
            }
        }
        if (unknownExist) {
            for (let j of Object.keys(rows[ProviderState.Unknown])) {
                if (!j) {
                    rows[ProviderState.Unknown][j].type = unknown;
                }
                assetCount.push(rows[ProviderState.Unknown][j]);
            }
        }
        return assetCount;
    }

    async fetchOldAssetByIds(assetIds) {
        try {
            const assets = await this.getByIds(assetIds);
            const result = [];
            for (const asset of assets) {
                if (asset.original.lastUpdated.source != LastUpdatedSource.FieldScan &&
                    Number(asset.original.lastUpdated.source) != LastUpdatedSource.FieldScan) {
                    result.push(asset);
                }
            }
            return result;
        } catch (error) {
            console.log('Error ', JSON.stringify(error));
            return [];
        }
    }


    fillRows(rows, provider, type, asset) {
        if (!rows[provider]) {
            rows[provider] = {};
        }
        if (!rows[provider][type]) {
            rows[provider][type] = {
                total: 0,
                provider: provider,
                type: type,
                inConflict: 0,
                autoResolved: 0,
                manuallyResolved: 0,
                readyForSNOW: 0,
                deferred: 0,
                unresolved: 0
            };
        }
        rows[provider][type].total++;
        switch (asset.state) {
        case AssetState.Unverified: rows[provider][type].unresolved++; break;
        case AssetState.InConflict: rows[provider][type].inConflict++; break;
        case AssetState.AutoVerified: rows[provider][type].autoResolved++; break;
        case AssetState.ManualVerified: rows[provider][type].manuallyResolved++; break;
        case AssetState.ApprovedForServiceNowUpdate: rows[provider][type].readyForSNOW++; break;
        case AssetState.Deferred: rows[provider][type].deferred++; break;
        default: break;
        }
    }

    async getAssetsForConflictResolve(assetIds, filters) {
        assetIds = [...new Set(assetIds)];
        const promises = [];
        for (let i = 0; i < assetIds.length; i += InParamMaxSize) {
            promises.push(
                this.getAll({
                    DOCUMENT_ID: {
                        matchMode: 'in',
                        value: assetIds.slice(i, i + InParamMaxSize)
                    },
                    state: {
                        matchMode: '==',
                        value: filters.state
                    }
                })
            );
        }

        const result = await Promise.all(promises);
        let assets = result.flat();
        const assetTypes = filters.assetTypes;
        assets = assetTypes && assetTypes.length > 0 ? assets.filter(x=> assetTypes.includes(x.original.typeId)) : assets;
        assets = assets.sort((a, b) => a.lastChanged.seconds - b.lastChanged.seconds);
        return assets;
    }
}

export default new AssetService();
